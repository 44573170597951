
<template>
  <div
    :class="{ 'ml-n4 mr-n3': !$vuetify.breakpoint.xs }"
    style="margin-top: -0.5px"
  >
    <v-btn
      small
      depressed
      :color="color"
      width="100%"
      class="white--text button"
      :style="this.row[this.header] == '0' ? 'pointer-events: none' : ''"
      @click="buttonClicked"
      style="height: 25px"
    >
      {{ row[header] }}
    </v-btn>
  </div>
</template>
<script>
export default {
  props: ["row", "header", "currentTable","result"],

  data() {
    return {
      selected: [],
    };
  },

  computed: {
    status() {
      return this.currentTable.header[this.header].status;
    },
    color() {
      let color = this.currentTable.header[this.header].color;
      if (this.row[this.header] == "0") {
        color += " lighten-3";
      }
      return color;
    },
  },
  methods: {
    buttonClicked() {
      if (this.row[this.header] != "0") {
        this.$router.push(
          this.result.testGroupPath + this.row.n_id + "&status=" + this.status
        );
      }
    },
  },
};
</script>
<style scoped>
.button {
  margin-top: -3px;
}
</style>
